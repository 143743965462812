import React, { useEffect, useState } from 'react';
import { Person, Block, Gear, ChevronLeft } from 'akar-icons';
import { Link, navigate } from 'gatsby';
import CustomFetch from '../components/fetch';

const Header = (props) => {
    const { title } = props;
    const [isOpen, setOpen] = useState('false');
    const [home, setHome] = useState('/');

    const settingsToggle = () => {
        setOpen(!isOpen);
    };

    const logout = (e) => {
        if (e) {
            CustomFetch(
                'logout',
                'POST',
                {},
                function (result) {
                    if (result.error === '') {
                        localStorage.clear();
                        navigate('/login');
                    }
                },
                function (error) {
                    if (error.includes('You are not authenticated')) {
                        localStorage.clear();
                        navigate('/login');
                    }
                }
            );
        }
    };

    useEffect(() => {
        CustomFetch(
            'checkAuthStatus',
            'POST',
            {},
            function (result) {
                if (result.user?.roles[0]?.name === 'Subcontractor') {
                    setHome('/document');
                } else {
                    setHome('/');
                }
            },
            function (error) {
                console.info(error);
            }
        );
    }, []);

    return (
        <header className="app-header">
            <div className="wrap">
                <div className="top">
                    <div className="top__back">
                        {title !== 'Dashboard' ? (
                            <Link to={home}>
                                <ChevronLeft size={24} />
                            </Link>
                        ) : null}
                    </div>
                    <div className="top__title">
                        <h1>{title}</h1>
                    </div>
                    <div className="top__action">
                        <button className="settings" onClick={settingsToggle}>
                            <Person size={14} />
                        </button>
                        <div
                            className={
                                isOpen ? 'settingslist' : 'settingslist open'
                            }
                        >
                            <ul>
                                <li>
                                    <Link to="/profile">
                                        <Gear size={14} /> Profile
                                    </Link>
                                </li>
                                <li>
                                    <button onClick={logout}>
                                        <Block size={14} /> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
