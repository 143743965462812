import React from 'react';
import Image from '../components/image';

const Footer = () => {
    return (
        <footer className="app-footer">
            <div className="wrap">
                <Image src="dawn-projects-logo.svg" alt="Dawn Projects" />
                <span>
                    App Powered by{' '}
                    <a
                        href="https://visns.studio"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Visns Studio
                    </a>
                </span>
            </div>
        </footer>
    );
};

export default Footer;
