import React, { useState, useEffect } from 'react';

const CustomMessage = (props) => {
    const { data } = props;
    const [showMessage, setShowMessage] = useState(false);
    const [messageClass, setMessageClass] = useState('dialog');
    const [messageContent, setMessageContent] = useState('');

    useEffect(() => {
        setShowMessage(data.showMessage);
        setMessageClass(data.messageClass);
        setMessageContent(data.messageContent);

        if (data.showMessage === true) {
            setTimeout(
                () => {
                    setShowMessage(false);
                },
                data.messageClass === 'dialog dialog--error' ? 10000 : 5000
            );
        }
    }, [data]);

    return (
        <>
            {showMessage === true ? (
                <div className={messageClass}>
                    <span>{messageContent}</span>
                </div>
            ) : null}
        </>
    );
};

export default CustomMessage;
