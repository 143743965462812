import React from 'react';

const PriorityModal = (props) => {
    const { setShowModal } = props;

    return (
        <div className="modal">
            <div className="modal__box">
                <div class="flex table-header">
                    <div class="flex-1">Request Type</div>
                    <div class="flex-1">Exampes</div>
                </div>
                <div class="flex table-body">
                    <div class="flex-1">Urgent</div>
                    <div class="flex-1">
                        Flickering lights, Power issues, Water flooding,
                        immediate safety issues, lifts not working, dislodged
                        ceiling panels
                    </div>
                </div>
                <div class="flex table-body">
                    <div class="flex-1">High</div>
                    <div class="flex-1">
                        Large room setups, cleaning issues, Coffee machines,
                        Watercoolers, Chilled taps
                    </div>
                </div>
                <div class="flex table-body">
                    <div class="flex-1">Medium</div>
                    <div class="flex-1">
                        Security bins, Airconditioning, Pest control, General
                        maintenance
                    </div>
                </div>
                <div class="flex table-body">
                    <div class="flex-1">Low</div>
                    <div class="flex-1">
                        Other incidental items, Supply boxes, Relocations
                    </div>
                </div>
                <div class="flex table-body">
                    <div class="flex-2">
                        <button
                            className="btn"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowModal(false);
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PriorityModal;
