import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import PriorityModal from '../components/modal';
import sal from 'sal.js';
import { Stop } from 'akar-icons';

const NewticketPage = () => {
    const [data, setData] = useState({
        building_id: '',
        priority_id: '',
        category_id: '',
        subcategory_id: '',
        status_id: 1,
        cai: '',
        location_of_issue: '',
        description: '',
        remote: true,
    });
    const [dataClass, setDataClass] = useState({
        building_id: 'formitem fw',
        priority_id: 'formitem fw',
        category_id: 'formitem fw',
        subcategory_id: 'formitem fw',
        cai: 'formitem fw',
        location_of_issue: 'formitem fw',
        description: 'formitem fw',
    });
    const [showError, setShowError] = useState(false);
    const [buildings, setBuildings] = useState([]);
    const [categories, setCategories] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });

    const handleChange = (e) => {
        if (e) {
            setData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));

            if (e.target.name === 'category_id') {
                CustomFetch(
                    'subcategories/dropdown',
                    'POST',
                    {
                        where: [
                            {
                                id: 'category_id',
                                value: e.target.value,
                            },
                        ],
                    },
                    function (result) {
                        setSubcategories(result.data);
                    },
                    function (error) {
                        setMessageSetting((prevState) => ({
                            ...prevState,
                            showMessage: true,
                            messageClass: 'dialog dialog--error',
                            messageContent: error,
                        }));
                    }
                );
            }
        }
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();

            let _error = false;

            if (
                data.cai === '' ||
                data.building_id === '' ||
                data.location_of_issue === '' ||
                data.priority_id === '' ||
                data.subcategory_id === '' ||
                data.description === ''
            ) {
                _error = true;
            }

            if (data.cai === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    cai: 'formitem fw inputerror',
                }));
            }

            if (data.building_id === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    building_id: 'formitem fw inputerror',
                }));
            }

            if (data.location_of_issue === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    location_of_issue: 'formitem fw inputerror',
                }));
            }

            if (data.priority_id === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    priority_id: 'formitem fw inputerror',
                }));
            }

            if (data.category_id === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    category_id: 'formitem fw inputerror',
                }));
            }

            if (data.subcategory_id === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    subcategory_id: 'formitem fw inputerror',
                }));
            }

            if (data.description === '') {
                setDataClass((prevState) => ({
                    ...prevState,
                    description: 'formitem fw inputerror',
                }));
            }

            if (_error === false) {
                CustomFetch(
                    'tickets',
                    'POST',
                    data,
                    function (result) {
                        if (result.error === '') {
                            navigate('/');
                        } else {
                            setMessageSetting((prevState) => ({
                                ...prevState,
                                showMessage: true,
                                messageClass: 'dialog dialog--error',
                                messageContent: String(result.error),
                            }));
                        }
                    },
                    function (error) {
                        setMessageSetting((prevState) => ({
                            ...prevState,
                            showMessage: true,
                            messageClass: 'dialog dialog--error',
                            messageContent: error,
                        }));
                    }
                );
            } else {
                setShowError(true);
            }
        }
    };

    useEffect(() => {
        CustomFetch(
            'buildings/dropdown',
            'POST',
            {},
            function (result) {
                setBuildings(result.data);
            },
            function (error) {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: error,
                }));
            }
        );

        CustomFetch(
            'categories/dropdown',
            'POST',
            {},
            function (result) {
                setCategories(result.data);
            },
            function (error) {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: error,
                }));
            }
        );

        CustomFetch(
            'priorities/dropdown',
            'POST',
            {},
            function (result) {
                setPriorities(result.data);
            },
            function (error) {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: error,
                }));
            }
        );

        CustomFetch(
            'checkAuthStatus',
            'POST',
            {},
            function (result) {
                setData((prevState) => ({
                    ...prevState,
                    cai: result.user.email,
                }));
            },
            function (error) {
                console.info(error);
            }
        );
    }, []);

    useEffect(() => {
        setTimeout(() => {
            sal();
        }, 100);
    }, [dataClass]);

    return (
        <Layout title="New Ticket">
            <Seo title="New Ticket" description="Dawn Projects Ticket System" />
            <CustomMessage data={messageSetting} />
            <form onSubmit={handleSubmit}>
                <div className="wrap">
                    <div className="ticketform">
                        {showError === true ? (
                            <div className="formitem ticketerror">
                                <Stop size={24} />
                                <span>
                                    Error! Please check the required fields.
                                </span>
                            </div>
                        ) : null}
                        <div
                            className={dataClass.cai}
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <label className="fi__label">
                                <input
                                    name="cai"
                                    onChange={handleChange}
                                    value={data.cai}
                                    type="text"
                                    placeholder=" "
                                />
                                <span className="fi__span">
                                    CAI <small>Required</small>
                                </span>
                            </label>
                        </div>
                        <div
                            className={dataClass.building_id}
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <div className="select">
                                <select
                                    name="building_id"
                                    onChange={handleChange}
                                    value={data.building_id}
                                >
                                    <option defaultValue hidden>
                                        Building
                                    </option>
                                    {buildings.length > 0
                                        ? buildings.map((a) => (
                                              <option
                                                  key={`buildings-${a.id}`}
                                                  value={a.id}
                                              >
                                                  {a.label}
                                              </option>
                                          ))
                                        : null}
                                </select>
                                <small>Required</small>
                            </div>
                        </div>
                        <div
                            className={dataClass.location_of_issue}
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <label className="fi__label">
                                <input
                                    name="location_of_issue"
                                    onChange={handleChange}
                                    value={data.location_of_issue}
                                    type="text"
                                    placeholder=" "
                                />
                                <span className="fi__span">
                                    Location of Issue{' '}
                                    <em>(floor &amp; desk location)</em>{' '}
                                    <small>Required</small>
                                </span>
                            </label>
                        </div>
                        <div
                            className={dataClass.priority_id}
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <div className="select">
                                <select
                                    name="priority_id"
                                    onChange={handleChange}
                                    value={data.priority_id}
                                >
                                    <option defaultValue hidden>
                                        Priority
                                    </option>
                                    {priorities.length > 0
                                        ? priorities.map((a) => (
                                              <option
                                                  key={`priorities-${a.id}`}
                                                  value={a.id}
                                              >
                                                  {a.label}
                                              </option>
                                          ))
                                        : null}
                                </select>
                                <small>Required</small>
                            </div>
                        </div>
                        <div
                            className="formitem fw"
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal(true);
                                }}
                                className="legendbtn"
                            >
                                View Priority Legend
                            </button>
                            {showModal === true ? (
                                <PriorityModal
                                    setShowModal={setShowModal}
                                ></PriorityModal>
                            ) : null}
                        </div>
                        <div
                            className={dataClass.category_id}
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <div className="select">
                                <select
                                    name="category_id"
                                    onChange={handleChange}
                                    value={data.category_id}
                                >
                                    <option defaultValue hidden>
                                        Category
                                    </option>
                                    {categories.length > 0
                                        ? categories.map((a) => (
                                              <option
                                                  key={`categories-${a.id}`}
                                                  value={a.id}
                                              >
                                                  {a.label}
                                              </option>
                                          ))
                                        : null}
                                </select>
                                <small>Required</small>
                            </div>
                        </div>
                        <div
                            className={dataClass.subcategory_id}
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <div className="select">
                                <select
                                    name="subcategory_id"
                                    onChange={handleChange}
                                    value={data.subcategory_id}
                                >
                                    <option defaultValue hidden>
                                        Subcategory
                                    </option>
                                    {subcategories.length > 0
                                        ? subcategories.map((a) => (
                                              <option
                                                  key={`subcategories-${a.id}`}
                                                  value={a.id}
                                              >
                                                  {a.label}
                                              </option>
                                          ))
                                        : null}
                                </select>
                                <small>Required</small>
                            </div>
                        </div>
                        <div
                            className={dataClass.description}
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <label className="fi__label">
                                <textarea
                                    name="description"
                                    onChange={handleChange}
                                    value={data.description}
                                    type="textarea"
                                    placeholder="Description"
                                    rows="10"
                                />
                                <span className="fi__span">
                                    <small>Required</small>
                                </span>
                            </label>
                        </div>
                        <div
                            className="formitem fw"
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <button type="submit" className="btn">
                                Submit Ticket
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    );
};

export default NewticketPage;
