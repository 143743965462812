import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Header from './header';
import Footer from './footer';
import CustomFetch from '../components/fetch';
import sal from 'sal.js';
import '../styles/main.scss';

const Layout = (props) => {
    const { children, title } = props;
    const [show, setShow] = useState(false);

    useEffect(() => {
        CustomFetch(
            'checkAuthStatus',
            'POST',
            {},
            function () {
                if (show === false) {
                    setShow(true);
                }
            },
            function (error) {
                if (error.includes('You are not authenticated')) {
                    localStorage.clear();
                    navigate('/login');
                }
            }
        );
    }, []);

    useEffect(() => {
        if (show === true) {
            sal();
        }
    }, [show]);

    return (
        <div id="app">
            {show === true ? (
                <>
                    <Header title={title} />
                    {children}
                    <Footer />
                </>
            ) : null}
        </div>
    );
};

export default Layout;
